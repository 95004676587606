<template>
  <CRow>
    <CCol col="12" xl="12">
      <transition name="slide">
        <ACRUDTable
          ref="crud_table"
          :fields="[
            //'id',
            getHelperObject('common_area'),
            helpers.identifier,
            { ...helpers.datetimeDate, key: 'min_date', label: 'Starts', helper: { ...helpers.datetimeDate.helper, id: 'min_date' }, render: datetime => this.$m(datetime, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD h:mm a') },
            { ...helpers.datetimeDate, key: 'max_date', label: 'Ends', helper: { ...helpers.datetimeDate.helper, id: 'max_date' }, render: datetime => this.$m(datetime, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD h:mm a') },
            { key: 'concurrency', label: 'People' }
          ]"
          :config="{
            url: 'admin/common_area_reservations',
            route: '/admin/tools/common_area_reservations',
            name: 'common_area_reservation',
            params: { _lists: 'common_areas' }
            //edit: false
          }"
          @preparedHelpers="onPreparedHelpers"
        />
      </transition>
    </CCol>
  </CRow>
</template>

<script>
import crudMixin from '@/app/views/_mixins/crud-mixin'

export default {
  name: 'CommonAreaReservations',
  mixins: [crudMixin]
}
</script>
